/**
 * Mobile Navigation
 */
( function() {
  const navToggle    = document.querySelector('#navtoggle');
  const navParent    = document.querySelector('.nav');
  const navMenuItems = document.querySelectorAll('#navmenu li');

  function init() {
    bindEvents();
  }

  function bindEvents() {
    navToggle.addEventListener('click', toggleNavMenu);

    navMenuItems.forEach(function(item){
      item.addEventListener('click', toggleNavMenu);
    });
  }

  function toggleNavMenu() {
    const isExpanded = navToggle.getAttribute('aria-expanded') === 'true' | false;

    navToggle.setAttribute('aria-expanded', !isExpanded);
    navParent.classList.toggle('open');
  }

  return init();
} )();
